import {TwoColumn} from '@/components/base/layouts/TwoColumn/TwoColumn';
import type {CardProps} from '@/components/base/modules/Card/Card';
import Card from '@/components/plus/redesign/section/Card/Card';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {type Mode} from '@/components/base/types';
import type {SectionHeaderProps} from '@/components/base/sections/SectionHeader/SectionHeader';

interface SideBySideImageListProps {
  className?: string;
  sectionHeader?: SectionHeaderProps;
  mode?: Mode;
  cardItems: CardProps[];
}

export default function SideBySideImageList({
  className,
  sectionHeader,
  mode = 'light',
  cardItems,
}: SideBySideImageListProps) {
  const getCards = () =>
    cardItems.map((card: CardProps, index: number) => (
      <Card
        className={
          card.headingGroup?.headingHtml ? 'flex items-top' : 'flex flex-row'
        }
        key={index}
        headingGroup={card.headingGroup}
        image={card.image}
        mode={mode}
        horizontal
        size="small"
      />
    ));

  return (
    <TwoColumn>
      <TwoColumn.Col1 className="md:col-span-5">
        <HeadingGroup className={className} {...sectionHeader} />
      </TwoColumn.Col1>
      <TwoColumn.Col2 className="md:col-span-7">{getCards()}</TwoColumn.Col2>
    </TwoColumn>
  );
}
