import Button, {
  type ButtonProps,
} from '@/components/base/elements/Button/Button';
import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import Link, {type LinkProps} from '@/components/base/elements/Link/Link';
import HeadingGroup, {
  type HeadingGroupContentProps,
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import type {Mode} from '@/components/base/types';
import {twMerge} from '@/stylesheets/twMerge';
import {replacePlaceholders} from '@/utils/utils';
import {useTranslations} from '@/hooks/useTranslations';

import {
  imageStyles,
  linkOrButtonStyles,
  wrapperStyles,
} from '../CardStats/styles';

const CARD_TYPES = ['default', 'outline', 'shadow', 'topDivider'] as const;

interface CardProps {
  button?: ButtonProps;
  className?: string;
  headingGroup?: HeadingGroupContentProps & {
    headingAs?: HeadingGroupProps['headingAs'];
    isKickerBadge?: HeadingGroupProps['isKickerBadge'];
  };
  horizontal?: boolean;
  image?: ImageProps;
  link?: LinkProps;
  mode?: Mode;
  size?: 'large' | 'small';
  type?: (typeof CARD_TYPES)[number];
}

function Card({
  button,
  className,
  headingGroup,
  horizontal = false,
  image,
  link,
  mode = 'light',
  size = 'large',
  type = 'default',
}: CardProps) {
  const imageIsVisible = image && type !== 'topDivider';
  const {t} = useTranslations();
  const ariaLabel = replacePlaceholders(
    t('global:ariaLabels.learnMore'),
    String(headingGroup?.headingHtml || ''),
  );

  const headingGroupProps = {
    ...headingGroup,
    mode,
    size: size === 'large' ? 't3' : 't5',
  };

  const buttonProps = {
    mode,
    size,
    intent: 'secondary' as 'primary' | 'secondary',
    ...button,
  } as ButtonProps;

  return (
    <div
      className={twMerge(wrapperStyles({horizontal, mode, type}), className)}
      data-mode={mode}
    >
      {imageIsVisible && (
        <div>
          <Image
            {...image}
            className={twMerge(imageStyles({horizontal}), image.className)}
          />
        </div>
      )}
      <div className="self-center">
        {headingGroup && <HeadingGroup {...headingGroupProps} />}
        {link && (
          <div
            className={linkOrButtonStyles({
              headingGroup: !!headingGroup,
            })}
          >
            <Link
              mode={mode}
              size={size}
              aria-label={link.text ? undefined : ariaLabel}
              {...link}
            >
              {link.text || t('global:learnMore')}
            </Link>
          </div>
        )}
        {button && (
          <div className="mt-md leading-[0]">
            <Button {...buttonProps} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Card;
